<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 511.995 511.995"
    style="enable-background:new 0 0 511.995 511.995;"
    xml:space="preserve"
  >
    <g>
      <path
        style="fill:#F6C454;"
        d="M387.216,81.865l-36.439,69.15c-10.277-6.499-32.684,2.922-44.032-1.801L362.617,45.77
		L387.216,81.865z"
      />
      <path
        style="fill:#F6C454;"
        d="M279.457,18.892v124.056c-7.708-3.089-15.542-4.681-23.464-4.681
		c-7.922,0-15.743,1.592-23.451,4.681V18.892H279.457z"
      />
    </g>
    <circle style="fill:#FED159;" cx="255.995" cy="294.725" r="217.269" />
    <path
      style="fill:#F6C454;"
      d="M57.618,294.724c0-116.83,92.214-212.107,207.825-217.055c-3.134-0.133-6.28-0.217-9.446-0.217
	c-119.996,0-217.271,97.275-217.271,217.271s97.275,217.271,217.271,217.271c3.166,0,6.312-0.083,9.446-0.217
	C149.832,506.831,57.618,411.553,57.618,294.724z"
    />
    <path
      style="fill:#FFE4A9;"
      d="M454.377,294.724c0-116.83-92.214-212.107-207.825-217.055c3.134-0.133,6.28-0.217,9.446-0.217
	c119.996,0,217.271,97.275,217.271,217.271s-97.275,217.271-217.271,217.271c-3.166,0-6.312-0.083-9.446-0.217
	C362.163,506.831,454.377,411.553,454.377,294.724z"
    />
    <circle style="fill:#A0DBFD;" cx="255.995" cy="294.725" r="170.316" />
    <path
      style="fill:#C1E9FD;"
      d="M255.997,124.413c-6.387,0-12.688,0.364-18.892,1.048c85.168,9.401,151.419,81.59,151.419,169.263
	s-66.251,159.863-151.419,169.263c6.204,0.685,12.505,1.048,18.892,1.048c94.06,0,170.311-76.251,170.311-170.311
	C426.309,200.664,350.057,124.413,255.997,124.413z"
    />
    <polygon
      style="fill:#FED159;"
      points="302.279,0 302.279,45.34 279.457,45.34 232.543,45.34 209.721,45.34 209.721,0 "
    />
    <polygon
      style="fill:#F6C454;"
      points="241.233,0 241.233,45.34 233.463,45.34 217.491,45.34 209.721,45.34 209.721,0 "
    />
    <polygon
      style="fill:#FED159;"
      points="420.693,48.162 399.798,88.401 387.216,81.865 367.153,71.436 354.735,64.988 
	342.153,58.451 363.06,18.212 388.048,31.197 "
    />
    <polygon
      style="fill:#F6C454;"
      points="388.048,31.197 367.153,71.436 354.735,64.988 342.153,58.451 363.06,18.212 "
    />
    <path
      style="fill:#96D5F6;"
      d="M255.997,124.413c6.387,0,12.688,0.364,18.892,1.048c-85.168,9.401-151.419,81.59-151.419,169.263
	s66.251,159.863,151.419,169.263c-6.204,0.685-12.505,1.048-18.892,1.048c-94.06,0-170.311-76.251-170.311-170.311
	C85.686,200.664,161.938,124.413,255.997,124.413z"
    />
    <path
      style="fill:#F6C454;"
      d="M255.997,466.925c-94.952,0-172.202-77.249-172.202-172.2s77.249-172.202,172.202-172.202
	c94.952,0,172.2,77.249,172.2,172.202C428.199,389.677,350.949,466.925,255.997,466.925z M255.997,140.621
	c-84.973,0-154.103,69.13-154.103,154.103s69.13,154.103,154.103,154.103s154.103-69.13,154.103-154.103
	C410.101,209.752,340.97,140.621,255.997,140.621z"
    />
    <polygon
      style="fill:#65B4BB;"
      points="315.414,250.535 315.414,225.346 268.592,225.346 268.592,192.898 243.403,192.898 
	243.403,225.346 196.581,225.346 196.581,307.32 290.224,307.32 290.224,338.914 196.581,338.914 196.581,364.103 243.403,364.103 
	243.403,396.551 268.592,396.551 268.592,364.103 315.414,364.103 315.414,282.131 221.771,282.131 221.771,250.535 "
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
